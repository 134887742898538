import React, { useState } from "react";
import axios from "axios";
import { graphql } from "gatsby";
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { useAuthContext } from "@fitplan/context/lib-es/auth";
import Layout from "../../components/layout/layout";
import { urls } from "../../config";
import { navigateTo } from "../../lib/UrlUtils";
import "./forgot-password.scss";

export const query = graphql`
  query ($languageISO: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
  }
`;

const ForgotPassword = ({ data: { ribbon, header, footer } }) => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [pending, setPending] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(true);
  const { t, language } = useTranslation();
  const { languages, changeLanguage } = useI18next();

  const authContext = useAuthContext();
  const { loggedIn } = authContext;
  if (loggedIn) {
    return navigateTo("/account");
  }

  const forgotPasswordSubmitHandler = async (e) => {
    setErrorMessage("");
    setSuccessMessage("");
    e.preventDefault();
    setPending(true);
    setErrorMessage("");
    try {
      const response = await axios({
        method: "POST",
        url: `${urls.forgotPassword}`,
        data: { email },
      });
      const { result, error } = response.data;
      if (result) {
        setSubmitted(true);
        setSuccessMessage(t("resetPasswordSuccess"));
      }
    } catch (err) {
      if (
        err.response &&
        err.response.data.error &&
        err.response.data.error === "user not found"
      ) {
        setErrorMessage(t("accountNotFound"));
      } else {
        setErrorMessage("Oops! Something went wrong.");
      }
    }
    setPending(false);
  };

  const checkEmail = (email) => {
    let filter =
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    let testResult = true;
    if (filter.test(email)) testResult = true;
    else {
      testResult = false;
    }
    return testResult;
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
    if (checkEmail(e.target.value)) {
      setButtonStatus(false);
    }
  };

  return (
    <Layout
      header={header}
      ribbon={ribbon}
      footer={footer}
      hasBanner="white-banner"
    >
      <section className="forgot-page">
        <div className="login-form-container">
          <h1>{t("resetYourPasswordText")}</h1>

          <div className="form-login">
            {submitted && <p className={"message success"}>{successMessage}</p>}
            {!submitted && (
              <>
                <div className="input-wrap">
                  <input
                    type="email"
                    placeholder={t("accountEmailAddressText")}
                    onChange={onEmailChange}
                  />
                  {errorMessage && (
                    <p className={"message error"}>{errorMessage}</p>
                  )}
                </div>
                <div className="input-wrap">
                  <button
                    type="button"
                    id="submitButton"
                    onClick={forgotPasswordSubmitHandler}
                    disabled={buttonStatus}
                  >
                    {t("resetPassword")}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ForgotPassword;
